import logo from './logo.svg';
import './App.css';

import React from "react";
import {
 BrowserRouter as Router,
 Routes,
 Route
} from "react-router-dom";

import MainPage from './Pages/main_page'

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<MainPage />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
