//
// nodejs (v18.17.0)
//
//
// Version of this file
// ==========================================================================================
// 2023.08.01                   (v0.0.1)
// ==========================================================================================
//
//
// This is a page of the website, its route => "/"
//
//
// Version of all packages
// ==========================================================================================
// react                        (v18.2.0)
// nexuni-react-web-tool        (v1.1.0)
// ==========================================================================================
import React, { Component } from 'react'
import GoogleMapReact from 'google-map-react';
// import {GoogleMapWithMarkers} from 'nexuni-react-web-tool';
// ==========================================================================================
//
// Content for this page
// ==========================================================================================
// MapInfo:                     Map1 information
// CAM_list:                    Camera buttons list
// LED_list:                    LED information list
// ==========================================================================================
// import {MapInfo, CAM_list, LED_list} from '../Configs/main_page_config.js'
// ==========================================================================================
//
// Css for this page
// ==========================================================================================
// main_page                    (v0.0.1)
// ==========================================================================================
import '../Styles/main_page.css';
// ==========================================================================================

// MainPage: contain a SwitchCardBar to switch between pages and a MainCard to show the main
// information of car 
class MainPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            title: "",
            title_list: ["服務", "服務據", "服務據點"],
            info: []
        }
    }

    componentDidMount() {

        fetch("https://gadgethi-css.s3.amazonaws.com/carday/latest_news.json")
        .then(res => res.json())
        .then(res => {
            console.log(res.info)
            this.setState({
                info: res.info
            })
        })
    }

    reveal(e) {
        // console.log("reveal")
        // var card_holder = document.querySelectorAll(".card_holder");

        // for (var i = 0; i < card_holder.length; i++) {
        //     console.log("for loop")
        //     var windowHeight = e.target.scrollTop;
        //     var elementTop = card_holder[i].getBoundingClientRect().top;

        //     console.log(windowHeight, elementTop)

        //     if (elementTop <= 0) {
        //         this.setState({
        //             title: this.state.title_list[i]
        //         })
        //     }
        // }
    }




    render() {

        return (
            <div>
                
            
            <div className="body" onScroll={(e) => {this.reveal(e)}}>
                {/*<div className="nav_bar">
                    <h3>{this.state.title}</h3>
                </div>*/}

                <div className="card_holder">
                    
                    <section id="section01" className="demo">
                      {/*<h1>Scroll Down Button #1</h1>*/}
                      <a href="#section02"><span></span>查看更多</a>
                      {/*<img className="main_img" src={"/main_pic2.png"}/>*/}
                      <div className="main_img"></div>
                    </section>
                </div>

                <div id="section02" className="card_holder">
                <div className="about_us_holder">
                    <h3 className="title">| 關於我們</h3>
                    <h5 className="content center">CARDAY 天天停車採用了<a className="custom-link" href="https://www.nexuni.com/TechPage/parking/ips">Nexuni Co. Ltd.</a>的全方位智慧停車解決方案，致力於為車主提供一個更省心、更安心、更貼心的停車環境：</h5>
                    <img src={"/slogan.png"} style={{ padding: 0, margin: 0, height: 'auto', width: '100vw' }}/>
                    <div className="news_card_holder background_grey">
                        <div className="news_card">
                            <h4 >更省心</h4>
                            <h5 className="content">具有辨識準確率高達99.5%的車牌辨識技術，並且使用邊緣運算可在150ms內辨識完畢，從而消除辨識過程的延遲，快速有效率！</h5>
                        </div>
                        <div className="news_card">
                            <h4 >更安心</h4>
                            <h5 className="content">CARDAY 天天停車引入的 Nexuni Co. Ltd. 智慧停車系統提供了高效、靈活且智能的後端管理平台系統，包括電子發票開立、月票註冊、遠端裝置管理、實時停車狀態監控，還能根據特定需求客製化停車導引和電動車充電管理等。再者，系統結合AI 影像分析技術，能即時偵測如違停、設施故障等異常情況，進而降低人工監控的需求。停車好放心！</h5>
                        </div>
                        <div className="news_card">
                            <h4 >更貼心</h4>
                            <h5 className="content">支持多種支付方式快速繳費，讓你輕鬆繳費不麻煩！</h5>
                        </div>
                        
                    </div>
                </div>
                    
                        
                </div>
                <h3 className="title">| 服務據點</h3>
                <div className="card_holder background_blue">
                    
                    <GoogleMapWithMarkers icon={"/carday_icon.svg"} file_name={"https://gadgethi-css.s3.amazonaws.com/carday/places.json"} gmap_key={process.env.REACT_APP_GMAP_KEY} />
                </div>

                <div className="card_holder">
                    <h3 className="title">| 最新消息</h3>
                    <div className="news_card_holder">
                        {this.state.info.map((item, i) => 
                            <div key={i} className="news_card">
                                <h4>{item.date}</h4>
                                <h5>{item.content}
                                <a className='custom-link' href={item.linkUrl}>{item.linkText}</a></h5>
                            </div>
                        )}
                        
                    </div>
                </div>

                

                <div className="footer">
                    <p>服務專線: 03-6218228</p>
                    <p>parking@nexuni.com</p>
                    <p>Copyright reserved by 耐思尼股份有限公司</p>
                </div>
            </div>
            </div>
        );
    }
}

export default MainPage;

// GoogleMapWithMarkers: a map componenet that can display google map with markers
class GoogleMapWithMarkers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      icon: this.props.icon ? this.props.icon : null, // icon: This is the path of icon image
      center_point: this.props.center_point ? this.props.center_point : [24.536339799336192, 121.03513621101547], // center_point: This is a list of the lat and lng point of the map center
      zoom: this.props.zoom ? this.props.zoom : 9, // zoom: The focus size of the map
      gmap_key: this.props.gmap_key, // gmap_key: This is the google map API key
      map_size: this.props.map_size ? this.props.map_size : ['80vh', '80vw'], // map_size: This is the size of the map
    }

    this.renderMarkers = this.renderMarkers.bind(this);
  }

  // renderMarkers: This function is used to generating markers when the map is loaded
  renderMarkers(map, maps) {
    var m_list = []
    var iw_list = []
    const icon_path = this.state.icon

    const regularIcon = {
        url: icon_path,
        scaledSize: new maps.Size(32, 32)
    }

    const largeIcon = {
        url: icon_path,
        scaledSize: new maps.Size(40, 40)
    }

    fetch(this.props.file_name)
    .then(res => {return res.json()})
    .then(res => {
      res.carpark.forEach(function (item, i) {
        var iw = new maps.InfoWindow({ content: item.content});
        var m = new maps.Marker(Object.assign({}, {icon: regularIcon, map}, item));

        m_list.push(m);
        iw_list.push(iw);

        // This is the popup function when the marker is clicked
        // m_list[i].addListener("click", () => {
        //   iw_list[i].open({
        //     anchor: m_list[i],
        //     map,
        //   });
        // });

        m_list[i].addListener("mouseover", () => {
          m_list[i].setIcon(largeIcon);
          iw_list[i].open({
            anchor: m_list[i],
            map,
          });
        });

        m_list[i].addListener("mouseout", () => {
          m_list[i].setIcon(regularIcon);
          iw_list[i].close();
        });

      })
    })
    
  }

  handleHomeClick = () => {
    this.state.map.setCenter({lat: 24.536339799336192, lng: 121.03513621101547});
    this.state.map.setZoom(9);
  }

  render() {
    const defaultProps = {
      center: {
        lat: this.state.center_point[0], 
        lng: this.state.center_point[1],
      },
      zoom: this.state.zoom
    };

    const mapStyles = [
        {
            "featureType": "all",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "weight": "2.00"
                }
            ]
        },
        {
            "featureType": "all",
            "elementType": "geometry.stroke",
            "stylers": [
                {
                    "color": "#9c9c9c"
                }
            ]
        },
        {
            "featureType": "all",
            "elementType": "labels.text",
            "stylers": [
                {
                    "visibility": "on"
                }
            ]
        },
        {
            "featureType": "landscape",
            "elementType": "all",
            "stylers": [
                {
                    "color": "#f2f2f2"
                }
            ]
        },
        {
            "featureType": "landscape",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#ffffff"
                }
            ]
        },
        {
            "featureType": "landscape.man_made",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#ffffff"
                }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "all",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "road",
            "elementType": "all",
            "stylers": [
                {
                    "saturation": -100
                },
                {
                    "lightness": 45
                }
            ]
        },
        {
            "featureType": "road",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#eeeeee"
                }
            ]
        },
        {
            "featureType": "road",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#7b7b7b"
                }
            ]
        },
        {
            "featureType": "road",
            "elementType": "labels.text.stroke",
            "stylers": [
                {
                    "color": "#ffffff"
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "all",
            "stylers": [
                {
                    "visibility": "simplified"
                }
            ]
        },
        {
            "featureType": "road.arterial",
            "elementType": "labels.icon",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "transit",
            "elementType": "all",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "all",
            "stylers": [
                {
                    "color": "#46bcec"
                },
                {
                    "visibility": "on"
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#c8d7d4"
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#070707"
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "labels.text.stroke",
            "stylers": [
                {
                    "color": "#ffffff"
                }
            ]
        }
    ]

    return (
      <div style={{ padding: 0, margin: '0 10vw 10vh 10vw', height: this.state.map_size[0], width: this.state.map_size[1] }}>
        <GoogleMapReact
          yesIWantToUseGoogleMapApiInternals={true}
          bootstrapURLKeys={{ key: this.state.gmap_key }}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
          onGoogleApiLoaded={({map, maps}) => {
            this.setState({map})
            this.renderMarkers(map, maps)
          }
          }
          options={{styles: mapStyles}}
        >
        </GoogleMapReact>
        <button className="cute-button" onClick={this.handleHomeClick}>查看所有CARDAY據點</button>
      </div>
    );
  }
}





